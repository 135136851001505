var formation = null;
var $desk = null;
var cnt = 0;
var player = [];
var name = null;

var $current_span = null;
var def_class = null;

$( function() {

  // set formation
  var $formation_list = $('.list-formation');

  $formation_list.find('a').on('click', function() {
    formation = $(this).attr('data-formation');
    $desk = $('.section-'+formation);
    $formation_list.find('a.is-selected').removeClass('is-selected');
    $(this).addClass('is-selected');
    $('.button-start').removeClass('is-disabled');
    return false;
  });


  $('.button-start').on('click', function() {
    $('.section0').hide();
    name = $('input.name').val();
    desk_init();
    set_current();
    button_init();

    ga('send', 'event', 'controller', 'start');

    return false;
  });


  // prev
  $('.button-prev').on('click', function() {
    // if($('.select-player').find('option:selected').val() === '') {
    //   $current_span
    //     .attr('class',def_class)
    //     .addClass('is-current')
    //     .addClass('else');
    // }

    cnt--;
    set_current();
    button_init();

    ga('send', 'event', 'controller', 'prev');

    return false;
  });

  // next
  $('.button-next').on('click', function() {
    // if($('.select-player').find('option:selected').val() === '') {
    //   $current_span
    //     .attr('class',def_class)
    //     .addClass('is-current')
    //     .addClass('else');
    // }

    cnt++;
    set_current();
    button_init();

    ga('send', 'event', 'controller', 'next');

    return false;
  });

  function button_init() {
    if(cnt >= 10) {
      $('.button-next').addClass('is-hide');
      $('.button-img').removeClass('is-hide');
      set_hidden_input();
    } else {
      $('.button-next').removeClass('is-hide');
      $('.button-img').addClass('is-hide');
    }
    if(cnt <= 0) {
      $('.button-restart').removeClass('is-hide');
      $('.button-prev').addClass('is-hide');
    } else {
      $('.button-restart').addClass('is-hide');
      $('.button-prev').removeClass('is-hide');
    }
  }

  function set_hidden_input() {
    $desk.find('.makeImage .fm').val(formation);
    $desk.find('.makeImage .nm').val(name);
    $.each(player, function(index, elem) {
      $desk.find('.makeImage .pl').eq(index).val(elem);
    });
  }

  // select
  $('.select-player').on('change', function() {
    var val = $(this).find('option:selected').val();
    if(val) {
      player[cnt] = val;
      $current_span
        .attr('class',def_class)
        .addClass('is-current')
        .addClass(val);
    } else {
      player[cnt] = '';
      $current_span
        .attr('class',def_class)
        .addClass('is-current')
        .addClass('else');
    }
    if(cnt === 10) {
      set_hidden_input();
    }
  });

  // init
  function desk_init() {
    $desk.show();
    $desk.find('.field span').each(function() {
      var class = $(this).attr('class');
      $(this).attr('data-def', class);
    });
  } 

  // set current
  function set_current() {
    $current_span = $desk.find('.field span').eq(cnt);
    def_class = $current_span.attr('data-def');
    $desk.find('.field span.is-current').removeClass('is-current');
    $desk.find('option').eq(0).prop('selected',true);
    $current_span.addClass('is-current');
  }

});